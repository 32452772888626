import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import {
  Container,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { FunctionComponent, useEffect } from "react";
import CopyButton from "./CopyButton";
import "./PaymentInstructions.css";
const axios = require("axios").default;

type PaymentInstructionsProps = {
  boxRequest: any;
  onPaid: (boxRequest: any) => void;
};

const PaymentInstructions: FunctionComponent<PaymentInstructionsProps> = ({
  boxRequest,
  onPaid,
}) => {
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const fetchBoxRequest = (id: string) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/box-request/${id}/`)
      .then((res: any) => {
        const fetchedBoxReq = res.data;
        if (fetchedBoxReq.payment_paid) {
          onPaid(fetchedBoxReq);
        } else {
          sleep(1000).then(() => fetchBoxRequest(id));
        }
      })
      .catch((e: any) => {
        console.error(e);
        sleep(1000).then(() => fetchBoxRequest(id));
      });
  };

  useEffect(() => {
    fetchBoxRequest(boxRequest.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Typography
        variant="body1"
        color="text.primary"
        marginY={3}
        align="center"
      >
        To complete your Anchor In a Box request, send us a Stellar payment to
        the following Stellar account:
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="payment instructions table">
          <TableBody>
            <TableRow>
              <TableCell className="w-20 fw-bold ps-3">Account:</TableCell>
              <TableCell align="right">{boxRequest.payment_pubkey}</TableCell>
              <TableCell align="center" className="w-10">
                <CopyButton text={boxRequest.payment_pubkey} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="w-20 fw-bold ps-3">Memo:</TableCell>
              <TableCell align="right">{boxRequest.payment_memo}</TableCell>
              <TableCell align="center" className="w-10">
                <CopyButton text={boxRequest.payment_memo} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="w-20 fw-bold ps-3">Asset:</TableCell>
              <TableCell
                sx={{ display: "flex", flexDirection: "column" }}
                align="right"
              >
                {boxRequest.payment_asset_code}
                <small>{boxRequest.payment_asset_home_domain}</small>
              </TableCell>
              <TableCell align="center" className="w-10">
                <CopyButton text={boxRequest.payment_asset_code} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="w-20 fw-bold ps-3">Amount:</TableCell>
              <TableCell align="right">{boxRequest.payment_amount}</TableCell>
              <TableCell align="center" className="w-10">
                <CopyButton text={boxRequest.payment_amount} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack alignItems="center">
        <TreeView
          aria-label="summary of your request"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          className="mt-3 summary-request"
        >
          <TreeItem nodeId="1" label="Summary of Your Request">
            <TreeItem nodeId="2" label={`Asset: ${boxRequest.asset_code}`} />
            <TreeItem
              nodeId="2"
              label={`Contact Email: ${boxRequest.customer_email}`}
            />
          </TreeItem>
        </TreeView>
        <Typography
          variant="body1"
          color="text.primary"
          align="center"
          alignItems="center"
          marginTop={2}
          marginBottom={0.5}
        >
          Awaiting payment
        </Typography>
        <LinearProgress sx={{ width: "8em" }} />
      </Stack>
    </Container>
  );
};

export default PaymentInstructions;
