import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FunctionComponent, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

type CopyButtonProps = {
  text: string;
  onCopy?: () => void;
};

const CopyButton: FunctionComponent<CopyButtonProps> = ({ text, onCopy }) => {
  const [copied, Set_copied] = useState<boolean>(false);
  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        Set_copied(true);
        setTimeout(() => Set_copied(false), 2000);
        onCopy?.();
      }}
    >
      {copied ? (
        <CheckIcon sx={{ fontSize: "1.2em", cursor: "pointer" }} />
      ) : (
        <ContentCopyIcon sx={{ fontSize: "1.2em", cursor: "pointer" }} />
      )}
    </CopyToClipboard>
  );
};

export default CopyButton;
