import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BoxRequestForm from "./BoxRequestForm";
import PaymentInstructions from "./PaymentInstructions";

const axios = require("axios").default;

const Steps = () => {
  const [activeStep, Set_activeStep] = useState<number | undefined>(undefined);
  const [boxRequest, Set_boxRequest] = useState<any | undefined>(undefined);
  const [searchParams, Set_searchParams] = useSearchParams();

  useEffect(() => {
    if (activeStep === undefined) {
      const id = searchParams.get("id");
      if (id) {
        axios
          .get(`${process.env.REACT_APP_API_URL}/box-request/${id}/`)
          .then((res: any) => {
            Set_boxRequest(res.data);
            if (res.data.payment_paid) {
              Set_activeStep(-1);
            } else {
              Set_activeStep(1);
            }
          })
          .catch((e: any) => {
            Set_activeStep(0);
            Set_searchParams({});
          });
      } else {
        Set_activeStep(0);
        Set_searchParams({});
      }
    }
  }, [searchParams, activeStep, Set_searchParams]);

  useEffect(() => {
    if (boxRequest !== undefined) {
      Set_searchParams({ id: boxRequest.id });
      if (boxRequest.payment_paid) {
        Set_activeStep(-1);
      } else {
        Set_activeStep(1);
      }
    }
  }, [boxRequest, Set_searchParams]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Container maxWidth="sm">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "30px",
            width: "100%",
          }}
        >
          <img width="70px" src="/bpventures.svg" alt="BP Ventures" />
        </div>
        <Typography variant="h4" color="white" align="center" marginTop={3}>
          BPV Anchor In a Box
        </Typography>
        <Typography variant="h6" color="white" align="center">
          Request Your Instance
        </Typography>
        <Typography
          sx={{ fontStyle: "italic" }}
          variant="body2"
          color="white"
          align="center"
          marginBottom={3}
        >
          Beta
        </Typography>
        <Card className="px-3 py-5 mb-5">
          {activeStep === undefined ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size={"3em"} />
            </Box>
          ) : activeStep !== -1 ? (
            <>
              <Stepper activeStep={activeStep} alternativeLabel>
                <Step>
                  <StepLabel>Asset & Contact Info</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Payment</StepLabel>
                </Step>
              </Stepper>
              {activeStep === 0 && (
                <BoxRequestForm
                  onSubmitSuccess={(boxReq) => Set_boxRequest(boxReq)}
                />
              )}
              {activeStep === 1 && (
                <PaymentInstructions
                  boxRequest={boxRequest}
                  onPaid={(boxReq) => Set_boxRequest(boxReq)}
                />
              )}
            </>
          ) : (
            <Grid
              container
              justifyContent="center"
              className="mt-2 mb-2"
              direction="column"
              alignItems="center"
            >
              <CheckIcon sx={{ fontSize: "4em" }} />
              <Typography variant="h5" color="text.primary" align="center">
                Success
              </Typography>
              <br />
              <Typography
                variant="body1"
                color="text.primary"
                align="center"
                paddingX={"1rem"}
                marginTop="1.5rem"
              >
                Your Anchor In a Box is being setup!
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.primary"
                align="center"
                paddingX={"1rem"}
                marginTop="0.5rem"
              >
                Our team will begin setting up your Anchor In a Box instance,
                we'll keep in touch via email!
              </Typography>
            </Grid>
          )}
        </Card>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img width="20px" src="/bpventures.svg" alt="BP Ventures" />
            <Typography variant="subtitle1" color="white" align="center">
              <span>&nbsp;BP Ventures</span>
            </Typography>
          </div>
          © 2022 All Rights Reserved
          <a
            rel="noreferrer"
            target="_blank"
            style={{ textDecoration: "none" }}
            href="https://bpventures.us"
          >
            bpventures.us
          </a>
        </div>
      </Container>
    </Box>
  );
};

export default Steps;
